<script setup lang="ts">
import { RequestReadSearchLocationInfos, RequestReadSearchLocations } from '@yescapa-dev/ysc-api-js/modern'
import type { APISearchLocationItem } from '@yescapa-dev/ysc-api-js/modern'
import { LOCALE_CODES } from '@yescapa-dev/ysc-components/i18n'
import type { SearchFilters } from '~~/types/api/search'

const {
  DE,
  EN_GB,
  IT,
  PT,
  FR,
  ES,
  NL,
} = LOCALE_CODES

const { $i18n: { localeProperties }, $apiConsumer } = useNuxtApp()

const results = ref<APISearchLocationItem[]>([])
const pendingQuery = ref<Partial<Pick<SearchFilters, 'where' | 'latitude' | 'longitude' | 'date_from' | 'date_to' | 'radius'>>>({
  where: undefined,
  latitude: undefined,
  longitude: undefined,
  date_from: undefined,
  date_to: undefined,
  radius: undefined,
})

const destination = computed(() => pendingQuery.value.where ?? '')

const onDestinationQueryChange = async (val: string | null) => {
  if (!val || val.trim().length < 3) {
    return
  }

  val = val.trim()
  try {
    results.value = await $apiConsumer.send(new RequestReadSearchLocations({ query: {
      search: val,
      language: localeProperties.value.yscCode,
    } }),
    )
  }
  catch (e) {
    console.error(e)
  }
}

const onDestinationChange = async (args: { locationId: string, label: string, countryCode: string } | null) => {
  if (!args) return

  const { locationId, label } = args
  let latitude, longitude, radius
  try {
    const data = await $apiConsumer.send(new RequestReadSearchLocationInfos({ query: { location_id: locationId } }))
    latitude = data.displayPosition.latitude
    longitude = data.displayPosition.longitude
    radius = data.radius
  }
  catch (e) {
    console.error(e)
    return
  }

  pendingQuery.value.where = label
  pendingQuery.value.latitude = latitude
  pendingQuery.value.longitude = longitude
  pendingQuery.value.radius = radius
}

const formatDateForQuery = (d: Date): string => {
  const year = d.getFullYear()
  const month = String(d.getMonth() + 1).padStart(2, '0')
  const day = String(d.getDate()).padStart(2, '0')

  return `${year}-${month}-${day}`
}

const onDatesChange = (evt: { start?: Date | null, end?: Date | null } | null) => {
  const { start, end } = evt || {}

  pendingQuery.value.date_from = start ? formatDateForQuery(start) : null
  pendingQuery.value.date_to = end ? formatDateForQuery(end) : null
}

const onEnterKeyPressed = async () => {
  if (!results.value.length) {
    return
  }
  const { locationId, label, countryCode: newCountryCode } = results.value[0]
  await onDestinationChange({ locationId, label, countryCode: newCountryCode })
}

const goToQuery = async () => {
  const { where, latitude, longitude, radius, date_from, date_to } = pendingQuery.value

  const domains: Record<string, string> = {
    [DE]: 'https://yescapa.de/s/',
    [EN_GB]: 'https://yescapa.co.uk/s/',
    [IT]: 'https://yescapa.it/s/',
    [PT]: 'https://yescapa.pt/s/',
    [FR]: 'https://yescapa.fr/s/',
    [ES]: 'https://yescapa.es/s/',
    [NL]: 'https://yescapa.nl/s/',
  }

  const url = new URL(domains[localeProperties.value.yscCode] || 'https://yescapa.com/s/')

  url.searchParams.set('page', '1')

  if (date_from && date_to) {
    url.searchParams.set('date_from', date_from)
    url.searchParams.set('date_to', date_to)
  }
  if (where) {
    url.searchParams.set('where', where)
  }
  if (latitude) {
    url.searchParams.set('latitude', latitude.toString())
  }
  if (longitude) {
    url.searchParams.set('longitude', longitude.toString())
  }
  if (radius) {
    url.searchParams.set('radius', radius.toString())
  }

  await navigateTo(url.toString(), { external: true })
}
</script>

<template>
  <div class="mx-auto max-w-screen-lg px-4 py-10">
    <div class="bg-peacock-700 px-4 md:px-8 py-6 space-y-4 md:space-y-6 sm:rounded-md">
      <span class="block text-white text-2xl font-semibold">{{ $t('rent_ideal_vehicle') }}</span>
      <YscSearch
        :results="results"
        :destination="destination"
        :locale="localeProperties.forceLocaleCode"
        with-submit-button
        with-destination-modal-mobile
        with-dates
        @destination-query-change="onDestinationQueryChange"
        @destination-change="onDestinationChange"
        @dates-change="onDatesChange"
        @enter-key-pressed="onEnterKeyPressed"
        @submit="goToQuery"
      >
        <template #destination-label>
          {{ $t('destination.label') }}
        </template>
      </YscSearch>
    </div>
  </div>
</template>
